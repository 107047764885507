import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function About() {
  return (
    <div>
      <div style={{ marginBottom: "2em" }} />
      <header>
        <Container>
          <Typography variant="h2">About Walltaker</Typography>
        </Container>
      </header>
      <main>
        <Container>
          <Paper elevation={0}>
            <p>
              Walltaker is inspired by the{" "}
              <a
                href="https://www.wallclaimer.com/"
                target="_blank"
                rel="noreferrer"
              >
                WallClaimer
              </a>{" "}
              app, which allows you to set the wallpaper of your friends phones.
              This however leads to some weird cases where people post stuff
              that'd be on your metaphorical blacklist. With that in mind, I
              wanted it to be restricted to{" "}
              <a href="https://e621.net" target="_blank" rel="noreferrer">
                e621.net
              </a>{" "}
              results, with an enforced blacklist. This keeps you in control,
              but not <i>too much</i> control.{" "}
            </p>
          </Paper>
          <center>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4}>
                <Button variant={"contained"} href="https://walltaker.joi.how/" target="_blank">
                  <Typography align="center" variant="button">
                    Open Web Portal
                  </Typography>
                </Button>
                <Typography variant="caption" display="block">
                  to sign in and set wallpapers
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button variant={"contained"} href="https://github.com/PawCorp/walltaker-desktop-client/releases/latest" target="_blank">
                  <Typography align="center" variant="button">
                    Download Desktop Client
                  </Typography>
                </Button>
                <Typography variant="caption" display="block">
                  Windows, macOS, Linux
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button variant={"contained"} href="https://github.com/PawCorp/walltaker-android-client/releases/latest/download/walltaker.apk" target="_blank">
                  <Typography align="center" variant="button">
                    Download Android Client
                  </Typography>
                </Button>
                <Typography variant="caption" display="block">
                  Android, APK File
                </Typography>
              </Grid>
            </Grid>
          </center>
          <div style={{ marginBottom: "5em" }} />
          <Paper elevation={0}>
            <Typography variant="h4">
              Found a bug? Want to see a new feature?
            </Typography>

            <Typography variant="body1">
              Our code is on GitHub! Feel free to open an issue for the
              appropriate item (bug or feature request) and to verify
              that there is not already an open issue for your
              request. If you are looking to contribute, be sure
              to read the CONTRIBUTING.md in the appropriate repo
              and follow all guidelines. 
            </Typography>

            <div style={{ marginBottom: "2em" }} />

            <center>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <Button variant={"contained"} href="https://github.com/PawCorp/walltaker/issues/new?assignees=&labels=bug&title=[Bug]+" target="_blank">
                    <Typography align="center" variant="button">
                      Web App Bug Report
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button variant={"contained"} href="https://github.com/PawCorp/walltaker/issues/new?assignees=&labels=enhancement&title=[Enhancement]+" target="_blank">
                    <Typography align="center" variant="button">
                      Web App Feature Reqeust
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Button variant={"contained"} href="https://github.com/PawCorp/walltaker-desktop-client/issues/new?assignees=&labels=bug&template=bug_report.md&title=%5BBug%5D+" target="_blank">
                    <Typography align="center" variant="button">
                      Desktop Client Bug Report
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Button variant={"contained"} href="https://github.com/PawCorp/walltaker-desktop-client/issues/new?assignees=&labels=enhancement&template=feature_request.md&title=%5BEnhancement%5D+" target="_blank">
                    <Typography align="center" variant="button">
                      Desktop Client Feature Request
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Button variant={"contained"} href="https://github.com/PawCorp/walltaker-android-client/issues/new?assignees=&labels=bug&template=bug_report.md&title=%5BBug%5D+" target="_blank">
                    <Typography align="center" variant="button">
                      Android Client Bug Report
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Button variant={"contained"} href="https://github.com/PawCorp/walltaker-android-client/issues/new?assignees=&labels=enhancement&template=feature_request.md&title=%5BEnhancement%5D+" target="_blank">
                    <Typography align="center" variant="button">
                      Android Client Feature Request
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </center>
          </Paper>
        </Container>
      </main>
      <div style={{ marginBottom: "2em" }} />
      <footer>
        <Container>
          <p>&copy; PawCorp {new Date().getFullYear()}</p>
          <Link to="/">Return to our homepage</Link>
        </Container>
      </footer>
      <div style={{ marginBottom: "4em" }} />
    </div>
  );
}
