import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../Pages/Home";
import About from "../Pages/About";
import Walltaker from "../Pages/Walltaker"
import Page from "../Page";

const Routing = (
  <Router>
    <Routes>
      <Route
        path="/"
        element={
          <Page title="PawCorp | Home">
            <Home />
          </Page>
        }
      />
      <Route
        path="/about"
        element={
          <Page title="PawCorp | About">
            <About />
          </Page>
        }
      />
      <Route
        path="/projects/walltaker"
        element={
          <Page title="PawCorp | Walltaker">
            <Walltaker />
          </Page>
        }
      />
    </Routes>
  </Router>
);

export default Routing;
