/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line
import React from "react";

import pawcorp_header_png from "../img/pawcorp_header.png";
import joihow_png from "../img/joihow.png";
import pawflix_png from "../img/pawflix.png";
import walltaker_png from "../img/walltaker.png";
import dot_gif from "../img/dot.gif";
import blackln_gif from "../img/blackln.gif";
import aboutpawcorp_png from "../img/aboutpawcorp.png";
import fire_gif from "../img/fire.gif";
import flamingskulls_gif from "../img/flamingskulls.gif";
import coolskull_gif from "../img/coolskull.gif";
import wow3_gif from "../img/wow3.gif";
import twitter_logo_png from "../img/twitter_logo.png";
import github_logo_png from "../img/github_logo.png";
import "../override.css";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div class="bodycontainer">
      <center>
        <table width="600" cellspacing="0" cellpadding="0" border="0">
          <tr>
            <td colspan="5" bgcolor="#1e3c77">
              <a href="/">
                <img
                  border="0"
                  src={pawcorp_header_png}
                  alt="Explore Our Website!"
                  width="600"
                  height="52"
                />
              </a>
            </td>
          </tr>

          <tr>
            <td bgcolor="#1e3c77" width="11">
              <img src={dot_gif} border="0" width="11" alt="" />
            </td>

            <td width="180" align="Center" valign="Top">
              <table width="180" cellspacing="0" cellpadding="0" border="0">
                <tr>
                  <td bgcolor="#3c63ae" align="LEFT" valign="TOP" width="180">
                    <img src={dot_gif} width="10" height="14" alt="dot" />
                    <b>PawCorp Twitter</b>
                    <br />
                    <img src={dot_gif} width="10" height="14" alt="dot" />
                    <b>Launched</b>
                    <br />
                    <font size="-1" face="Futura MD,Arial,Helvetica">
                      <img src={dot_gif} width="10" height="20" alt="dot" />
                      Check out the totally
                      <br />
                      <img src={dot_gif} width="10" height="14" alt="dot" />
                      new Twitter for our
                      <br />
                      <img src={dot_gif} width="10" height="14" alt="dot" />
                      <a
                        href="https://twitter.com/Paw_Corp"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <font color="#FFFFFF">projects</font>
                      </a>
                      <br />
                      <img src={dot_gif} width="10" height="20" alt="dot" />
                      <br />
                    </font>
                  </td>
                </tr>

                <tr>
                  <td bgcolor="#3c63ae" width="180">
                    <img
                      src={blackln_gif}
                      width="180"
                      height="11"
                      alt="black line"
                    />
                    <br />
                    <a href="#">
                      <img
                        src="img/none.png"
                        border="0"
                        width="180"
                        height="329"
                      />
                    </a>
                  </td>
                </tr>
              </table>
            </td>

            <td bgcolor="#1e3c77" width="11" valign="TOP">
              <img src={dot_gif} border="0" width="11" alt="" />
            </td>

            <td valign="TOP" width="387">
              <img
                src={dot_gif}
                border="0"
                width="386"
                height="1"
                alt=""
                hspace="0"
                vspace="0"
              />
              <br />
              <table cellpadding="5" cellspacing="0" border="0">
                <tr>
                  <td valign="top" align="left">
                    <p>
                      <a
                        href="https://joi.how"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={joihow_png}
                          border="0"
                          alt="JOI.how"
                          width="267"
                          height="84"
                        />
                      </a>
                      <br />
                      {/* <a
                        href="https://walltaker.joi.how"
                        target="_blank"
                        rel="noreferrer"
                      > */}
                      <Link to="/projects/walltaker">
                        <img
                          src={walltaker_png}
                          border="0"
                          alt="Walltaker"
                          width="265"
                          height="83"
                        />
                      </Link>
                      {/* </a> */}
                    </p>
                    <br />
                    <br />
                    <br />
                    <center>
                      <img src={fire_gif} height="50" width="auto" alt="fire" />
                      <img
                        src={flamingskulls_gif}
                        height="50"
                        width="auto"
                        alt="flaming skulls"
                      />
                      <img
                        src={coolskull_gif}
                        height="50"
                        width="auto"
                        alt="cool skull"
                      />
                      <img
                        src={flamingskulls_gif}
                        height="50"
                        width="auto"
                        alt="flaming skulls"
                      />
                      <img src={fire_gif} height="50" width="auto" alt="fire" />
                    </center>
                  </td>

                  <td valign="TOP" align="LEFT" width="100" nowrap>
                    <font size="-1">
                      <img src={wow3_gif} width="50" height="auto" alt="WOW" />
                      <br />
                      <a
                        href="https://twitter.com/Paw_Corp"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={twitter_logo_png}
                          border="0"
                          width="18"
                          height="18"
                          alt="Twitter logo"
                        />
                        @Paw_Corp
                        <br />
                      </a>
                      <a
                        href="https://github.com/PawCorp"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={github_logo_png}
                          border="0"
                          width="18"
                          height="18"
                          alt="GitHub Logo"
                        />{" "}
                        @PawCorp
                        <br />
                      </a>

                      <a href="/">
                        <font color="#9E33FF">Home</font>
                      </a>

                      <p>
                        <img
                          src={aboutpawcorp_png}
                          border="0"
                          width="95"
                          height="20"
                          alt="About PawCorp"
                        />
                        <br />
                        <a href="/about">
                          <font color="#3399CC">
                            About
                            <br />
                            <img src={dot_gif} width="9" border="0" alt="dot" />
                            PawCorp
                          </font>
                        </a>
                        <br />
                      </p>
                    </font>
                  </td>
                </tr>
              </table>
              <center>
                <p></p>
              </center>
            </td>

            <td bgcolor="#1e3c77" width="11">
              <img src={dot_gif} border="0" width="11" alt="" />
            </td>
          </tr>

          <tr>
            <td bgcolor="#1e3c77" colspan="5">
              <img src={dot_gif} width="600" height="11" border="0" alt="" />
            </td>
          </tr>
        </table>
      </center>
      <center style={{ marginBottom: "3em", color: "#000" }}>
        <p>&copy; PawCorp {new Date().getFullYear()}</p>
        <p style={{fontSize: "0.5em"}}>This page has NOT been optimized for browser versions above 3.0</p>
      </center>
    </div>
  );
}

export default Home;
