import { Container, Paper } from "@mui/material";
import React from "react";

export default function About() {
  return (
    <div>
      <header>
        <Container>
          <h1>PawCorp</h1>
        </Container>
      </header>
      <main>
        <Container>
          <Paper elevation={0}>
            <p>Coming Soon</p>
          </Paper>
        </Container>
      </main>
      <footer>
        <Container>
          <p>&copy; PawCorp {new Date().getFullYear()}</p>
        </Container>
      </footer>
    </div>
  );
}
