/* eslint-disable no-unused-vars */
import { createTheme, ThemeProvider } from '@mui/material/styles';
export const pawCorpTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#C2F2F2',
    },
    secondary: {
      main: '#7EE69E',
      light: '#9ED982',
    },
    error: {
      main: '#DB8693',
    },
    warning: {
      main: '#C2F2F2',
    },
    info: {
      main: '#3D734A',
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 4,
  },
});

export function PawCorpThemeProvider ({ children }) {
  return (
    <ThemeProvider theme={pawCorpTheme}>
      {children}
    </ThemeProvider>
  )
}