import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';

import { PawCorpThemeProvider } from "./theme";
import CssBaseline from '@mui/material/CssBaseline';

import Routing from "./Routing/Router";

ReactDOM.render(
  <React.StrictMode>
    <PawCorpThemeProvider>
      <CssBaseline />
      {Routing}
    </PawCorpThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
